
import './App.css';
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import {collection, getFirestore,getDocs} from 'firebase/firestore'; // Firebase Modular SDK импорты
import { initializeApp } from 'firebase/app';
import React, {useState, useEffect } from 'react';

import { AdRentMoto } from './js/AdRentMoto';
import {NotFound} from './js/notFound'
import { HomePageNew } from './js/HomePageNew';
import { Profile } from './js/Profile';
import { ProfilePage } from './js/ProfilePage';
import { CreateRentBike } from './js/CreateRentBike';
import { Search } from './js/Search';
import { Reader } from './js/Reader';
import { AllAdGenre } from './js/AllAdGenre';
// import { ListSaveAds } from './js/ListSaveAds';
import { RedAdd } from './js/RedAdd';
import { ListSaveProfiles } from './js/ListSaveProfiles';

import firebaseConfig from './js/firebaseConfig';
import { LoadingScreen } from './js/LoadingScreen';
// import { Tester } from "./js/Tester"




const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

function App() {

  //Сделано для того чтобы успело все програузится, в противном случае отображается сначала нотФаунд
  const [isLoading, setIsLoading] = useState(false);

  useEffect(()=>{
    const delay = setTimeout(() => {
      setIsLoading(true);
    }, 3000);
  
    return () => {
      clearTimeout(delay);
    };
  
  },[])

  //Данные пользователя телеграмм
  const [userData, setUserData] = useState(null)

  useEffect(() => {
    if (window.Telegram) {
      const tg = window.Telegram.WebApp;
      const gl = window.Telegram
      tg.ready();
      tg.setHeaderColor('#cce3fd')
      tg.setBackgroundColor('#ffffff')
      tg.disableVerticalSwipes()
      tg.lockOrientation()
      // tg.exitFullscreen()
      console.log('Telegram WebApp initialized:', tg);
      console.log('Глобальный ТГ:', gl);
      // console.log('Проверяем, возможно данные чата:', tg.initData);
      // console.log("Данные пользователя", tg.initDataUnsafe.user)
      // console.log(tg.initDataUnsafe.user)
      setUserData(tg.initDataUnsafe.user)
      console.log("ДАННЫЕ ПОЛЬЗОВАТЕЛЯ", tg.initDataUnsafe.user)
      // tg.SettingsButton.show();
      tg.onEvent('settingsButtonClicked', () => {
        console.log('Settings button was clicked');
      });

    } 
  }, []);


  const [allData, setAllData] = useState(null)

  useEffect(()=>{  
    // Функция для получения данных пользователя по его id
    const getAllData = async () => {
      try {
        const usersCollectionRef = collection(db, 'selbo');
        const querySnapshot = await getDocs(usersCollectionRef);

        if (querySnapshot.empty) {
          console.log("Нет данных")
          return null;
        }
        const getAllData = [];
        querySnapshot.forEach((doc) => {
            getAllData.push({ id: doc.id, ...doc.data() });
        });
        console.log("Извлеченные данные книги", getAllData)
        setAllData(getAllData)        
        return getAllData;
      } catch (error) {
        console.error('Ошибка при получении данных пользователя:', error);
        return null;
      }

    };

    //Функция отображения объявлений
    const allAdd = () => {
        getAllData().then((getAllData) => {
      if (getAllData) {
        setAllData(getAllData)
      }
    });
    }
    allAdd()
    },[])



  
  return (

    <Router >
      <div className="cont">
      <Routes>
      {/* <Route path="/" element={
        <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
      <LoadingScreen data={allData}/>s
      <p style={{magin:"0px", marginTop:"10px", color:"black", fontSize:"18px"}}>Загрузка книги...</p>
      </div>
      } /> */}
      {/* <Route path="/" element={<Tester/>} /> */}
      <Route path="/" element={<HomePageNew data={allData}/>} />
      <Route path="profile" element={<Profile userData={userData} allData={allData}/>} />
      <Route path="addrentbike" element={<CreateRentBike userData={userData}/>} />
      <Route path="profilepage" element={<ProfilePage allData={allData}/>} />
      <Route path="search" element={<Search allData={allData}/>} />
      <Route path="genre" element={<AllAdGenre allData={allData}/>} />
      {/* <Route path="saveads" element={<ListSaveAds allData={allData}/>} /> */}
      <Route path="savepropfiles" element={<ListSaveProfiles allData={allData}/>} />
        {allData && allData.map((ad, ind)=>
        <Route key={ind} path={`/ad${ad.formItems.art}`} element= {<AdRentMoto ad={ad.formItems} userData={userData}/>}/>
        )}
        {allData && allData.map((ad, ind)=>
        <Route key={ind} path={`/redad${ad.formItems.art}`} element= {<RedAdd ad={ad.formItems} userData={userData} id={ad.id}/>}/>
        )}
        {allData && allData.map((ad, ind)=>
        <Route key={ind} path={`/read${ad.formItems.art}`} element= {<Reader ad={ad.formItems} userData={userData}/>}/>)}
        {isLoading ? <Route path="*" element={<NotFound />} />: <Route path="*" element={<LoadingScreen/>}/>}
      </Routes>
      </div>
    </Router>

  );
}

export default App;
