import '../css/Profile.css'
import back from '../image/leftBl.png'
import {useNavigate, useLocation } from 'react-router'
import sell from '../image/user.png'
import users from '../image/folowerUsers.png'
// import ads from '../image/folowerAdd.png'
// import clock from '../image/clock.png'
import favorite from '../image/favorite.png'
import dots from '../image/dots.png'
import { useEffect, useState } from 'react'
// import { Link } from 'react-router-dom'
import getSaveAds from './getSaveAds'
import redAdFunction from './redAdFunction'
import ListSaveAds from './ListSaveAds'
import hudLet from '../image/hudLet.png'
import selbo from '../image/selboEmpty.png'
import bookRead from '../image/contRead3.png'
import getBookLocal from './getBookLocal';
import users2 from '../image/users.png'


const Profile = props => {
    const navigate = useNavigate()
    const moment = require('moment');
    require('moment/locale/ru');

    const [myBooksShow, setMyBooksShow] = useState(true)

    const showBooks = () => {
        setMyBooksShow(!myBooksShow)
    }

    const stepBack = () => {
        navigate(-1)
    }
    //Данные пользователя телеграмм
    const [userData, setUserData] = useState(null)
    useEffect(() => {
         if (window.Telegram) {
              const tg = window.Telegram.WebApp;
              tg.ready();
              setUserData(tg.initDataUnsafe.user)
        } else {const u = {
                    username:"Leonid2323",
                    first_name:"Leonid",
                    last_name:"Sushkov"
                }
                console.log(u)
                setUserData(u)
        }
        }, []);

    const [myAds, setMyAds] = useState(null)

    //Сохраненные объявления
    const [listData, setListData] = useState(null)
    useEffect(() => {
        const fetchData = async () => {
          const arr = await getSaveAds("arrArts1")
        //   console.log("Вытащенные данные из локал стораге!!!", arr);
          if (arr) {
            // console.log("Вытащенные данные из локал стораге", arr);
            setListData(arr)
          }
        };
    
        fetchData();
      }, [props]);
      
//Мои объявления
useEffect(() => {
    console.log(props.userData)
    const fetchData = () => {
      const allAds = props.allData;
      let userName = null
      if (userData) {
      userName = userData.username;
      }
    // const userName = "Leonid2323";
      console.log("ИМЯ!!!", userName)
      if (allAds && userName) {
        console.log("Все данные из props:", allAds);
        console.log("Имя пользователя:", userName);
        const filteredAds = allAds.filter(ad => ad.formItems.telegram === userName);
        setMyAds(filteredAds);
        console.log("Отфильтрованные объявления:", filteredAds);
      }
    };
  
    fetchData();
  }, [props, userData]);

  //Редактирование объявления (продано, не продано)
  const changeShow = (show, ad) => {
    console.log("Нажали кнопку", show)
    const updatedAd = {
      ...ad,
      formItems: {
        ...ad.formItems,
        clientNotShow: show
      }
    };
    const newData = { ...updatedAd, formItems: updatedAd.formItems };
    
    redAdFunction(updatedAd.id, newData)
  };


    const getModal = () => {
        navigate('/addrentbike')
    }


    const goToSaveUsers = () => {
        navigate('/savepropfiles')
    }

    const goToRed = (ad) => {
        console.log("Данные до передачи", ad)
        navigate(`/redad${ad.formItems.art}`)
    }

    const goToAd = (url) => {
        navigate(url)
    }
    const goToRead = () => {
        const key = 'nameBook'
        getBookLocal(key, (error, data) => {
            if (error) {
              console.error("Ошибка:", error);
            } else if (data) {
            const linkBook = data.link
            const art = data.art
            const page = data.page
            const part = data.part
            navigate(`/read${data.art}`, { state: { linkBook, art, page, part} })
              console.log("Полученные данные:", data);
            } else {
              console.log("Данные не найдены");
            }
          });
      }


    return (
        <div>
            {/* <div className="contHeadProfile">
                <img src={back} alt="<" className="profileBackImg" onClick={stepBack}/>
                <p style={{fontSize:"18px"}}>Вы</p>
            </div> */}
            {userData ?
            <div className="contHMarketProfile">
            <div className="contName">
                <img src={userData.photo_url} alt="U" className="imgProfile"/>
                {/* <img src={props.userData.photo_url ? props.userData.photo_url : sell} alt="U" className="imgProfileTg"/> */}
                <p className="textNameProfile">{userData.first_name} {userData.last_name}</p>
            </div>
            <div>
            <img src={bookRead} alt="u" className="imgUserProfile" onClick={goToRead}/>
            <img src={selbo} alt="u" style={{marginLeft:"8px"}} className="imgUserProfile" onClick={()=> navigate('/')}/>
            </div>
            </div>

:
            <div className="contHMarketProfile">
            <div className="contName">
                <img src={sell} alt="U" className="imgProfile"/>
                {userData ? <p>{userData.first_name} {userData.last_name}</p>: <p className="textNameProfile">Пользователь Телеграм</p>}
            </div>
            <div>
            <img src={bookRead} alt="u" className="imgUserProfile" onClick={goToRead}/>
            <img src={selbo} alt="u" style={{marginLeft:"8px"}} className="imgUserProfile" onClick={()=> navigate('/')}/>
            </div>
            </div>   
}
    <div className="contFavoriteProfile">
        <div className="contFollowers">
            <div className="folower" onClick={goToSaveUsers}>
                <img className="imgElementFol" src={users} alt="o" style={{marginRight:"10px"}}/>
                <p className="textElementFol">Подписки 13</p>
            </div>
            <div className="folower" onClick={goToSaveUsers}>
                <img className="imgElementFol" src={users2} alt="o" style={{marginRight:"10px"}}/>
                <p className="textElementFol">Подписчики 165</p>
            </div>
        </div>
        <div className="twoBlocksProfile">
        {/* <Link to={listData ? "/saveads":"#"} style={{textDecoration:"none", color:"black", width:"49%"}}> */}
        <div className="leftElement" onClick={()=> setMyBooksShow(true)} style={{boxShadow: myBooksShow ? "0px 0px 8px 1px rgba(0, 0, 0, 0.1)": ""}}>
            <img className="imgElementProfile" src={hudLet} alt="o"/>
            {listData ? <p className="textElementProfile">Мои книги: {myAds.length}</p>:<p className="textElementProfile">Мои книги: 0</p>}
        </div>
           <div className="leftElement" onClick={()=> setMyBooksShow(false)} style={{boxShadow: !myBooksShow ? "0px 0px 8px 1px rgba(0, 0, 0, 0.1)": ""}}>
                <img className="imgElementProfile" src={favorite} alt="o"/>
                {listData ? <p className="textElementProfile">Сохраненные книги: {listData.length}</p>:<p className="textElementProfile">Сохраненные книги: 0</p>}
            </div>
        {/* </Link> */}
        {/* <div className="leftElement" onClick={goToSaveUsers}>
                <img className="imgElementProfile" src={users} alt="o"/>
                <p className="textElementProfile">Подписки на пользователей</p>
            </div> */}

        </div>
        {/* <div className="twoBlocksProfile" style={{borderBottom:"1px solid rgb(236, 236, 236)", paddingBottom:"3%"}}>
        <div className="leftElement">
                <img className="imgElementProfile" src={clock} alt="o" style={{width:"23px", height:"23px"}}/>
                <p className="textElementProfile">Недавно просмотренные</p>
            </div>
            <div className="leftElement">
                <img className="imgElementProfile" src={ads} alt="o" style={{width:"23px", height:"23px"}}/>
                <p className="textElementProfile">Подписки на объявления</p>
            </div>
        </div> */}
    </div>
    {myBooksShow ? <p style={{fontSize:"18px", fontWeight:"600", marginLeft:"3%"}}>Ваши книги</p>:<p style={{fontSize:"18px", fontWeight:"600", marginLeft:"3%"}}>Сохраненные книги</p>}
    {myBooksShow ? <div className="allMyAds">
                {myAds ? myAds.map((ad, id) => (
                    <>
                    <div className="eachmyAd" key={id} style={{marginBottom:"20px"}}>
                    <div style={{color:"black",display:"flex", width:"100%"}}>
                        <div style={{position:"relative", width:"18%", paddingTop:"18%", borderRadius:"10px", marginRight:"3%"}}>
                         <img style={{position:"absolute", top:"0", left:"0", width:"100%", height:"100%"}} src={ad.formItems.imageUrl[1]} alt="?" className="imgMyAds" onClick={()=>goToAd(`/ad${ad.formItems.art}`)}/>
                         </div>
                    <div className="contTextMyAds" onClick={()=>goToAd(`/ad${ad.formItems.art}`)}>
                        <p className="text1MyAd">{ad.formItems.nameBook}</p>
                        <p className="text2MyAd" >{ad.formItems.author}</p>
                        <p className="text3MyAd">Сохранено {moment(ad.formItems.dateAd).format('LL')}</p>
                    </div>
                    </div>
                    <img src={dots} className="imgMyAdsRight" alt="..." onClick={()=>{goToRed(ad)}}/>
                    </div>
                    {/* {ad.formItems.clientNotShow ? <div className="btnSell" style={{backgroundColor:"rgb(218, 218, 218)"}} onClick={()=> changeShow(false, ad)}>Снять отметку "продано"</div>: <div className="btnSell" onClick={()=> changeShow(true, ad)}>Отметить как "продано"</div>} */}
                    {/* <div className="btnPromote" >Продвигать объявление"</div> */}
                    </>
                )
            ):
                <p style={{}}>Нет опубликованных книг</p>
            }
    </div>:
    <ListSaveAds allData={props.allData}/>
    }
    <div style={{width:"94%", marginLeft:"3%", marginRight:"3%",marginBottom:"30px", borderTop:"1px solid rgb(194, 194, 194)"}}>
        <div className="btnAdd" onClick={getModal}>
            <p>Опубликовать новую книгу</p>
        </div>
    </div>
        </div>
    )
}

export {Profile}